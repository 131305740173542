/* 通知履歴画面CSS */

#right_contents.rireki{
	margin-left: 221px;
	flex-basis: calc(100% - 221px);
	width: 100%;
	display: block;
}
@media all and (max-width: 1300px) {
	#right_contents {
		margin-left: 72px;
		flex-basis: calc(100% - 72px);
		width: 100%;
		display: block;
	}
}
#history_index.rireki {
	display: block;
	width: 100%!important;
	max-width: 100%!important;
	border-radius: 0px;
	padding: 20px;
	position: relative;
   background-color: #fff;
}
#history_detail.rireki {
	display: block;
	width: 100%!important;
	max-width: 100%!important;
	border-radius: 0px;
	padding: 20px;
	position: relative;
   background-color: #fff;
}
#history_index.rireki h1 {
    font-weight: bold;
    font-size: 25px;
}
#history_detail.rireki h1 {
    font-weight: bold;
    font-size: 25px;
}
#history_index.rireki h3 {
    font-weight: bold;
    font-size: 20px;
}
.busadd_btnarea {
	width: 100%;
	display: flex;
	justify-content: right;
	align-items: center;
}
#history_detail.rireki .btn_back {
	flex-basis: auto;
}
#history_detail.rireki .btn_back {
	margin: 0 10px;
	border: none;
	cursor: pointer;
	text-align: center;
	background-color: rgb(255,255,255);
	font-size: 16px;
	display: inline-block;
	padding: 8px 12px;
	border-radius: 4px;
	color: rgb(17,85,161);
	text-decoration: none!important;
}
#history_detail.rireki .btn_back:hover {
	text-decoration: underline!important;
}
#history_index .tablearea {
    margin-top: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}
#history_index table.rirekiindex_table {
    margin: 0 auto 4px auto;
	width: 95%;
	font-size: 12px;
	border-collapse: collapse;
	table-layout: fixed;
}
#history_index table.rirekiindex_table tr {
    border-bottom: 1px solid rgb(218,218,218);
    height: 35px;
}
#history_index table.rirekiindex_table tr.index {
	width: 100%;
	background-color: rgb(255,255,255);
	height: 45px;
	font-size: 14px;
}
#history_index table.rirekiindex_table tr:hover {
	cursor: pointer;
	background-color: rgb(234,238,243);
	transition: 0.3s all ease 0s;
}
#history_index table.rirekiindex_table th {
    padding: 8px 2px;
	text-align: center;
	background-color: rgb(239,239,239);
	font-weight: bold;
}
#history_index table.rirekiindex_table th.sticky {
    position: sticky;
	top: -1px;
	z-index: 999;
	cursor: default;
}
#history_index table.rirekiindex_table td {
    padding: 0px 2px;
	min-height: 44px;
	max-height: 44px;
	min-width: 405px;
    max-width: 405px;
	text-align: center;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#history_index table.rirekiindex_table td.title {
	text-align: left;
}
#history_detail .detail .box .bold {
	font-weight: bold;
}
#history_detail .detail div {
	min-width: 100%;
	max-width: 100%;
	word-wrap: break-word;
	overflow-wrap: break-word;
}
#history_detail .detail .box {
	width: 100%;
	height: auto;
   	margin: 20px 10px 5px 0;
	background-color: rgb(255,255,255);
	border: 1px solid rgb(17,85,161);
   	border-radius: 4px;
}
#history_detail .detail dd.location {
	display: inline-block;
	position: absolute;
	margin: -10px 0 5px 10px;
	padding: 0 5px 0 5px;
	border: none;
	color: #333;
	font-weight: bold;
	font-size: 20px;
	background-color: rgb(255,255,255);
}
#history_detail .detail dd {
	margin: 20px 0px 10px 10px;
	font-size: 15px;
	word-break: break-word;
}
#history_detail .detail span {
	margin-left: 10px;
	font-size: 15px;
	word-break: break-word;
}
#history_detail .detail .box .margin {
	margin: 20px 0 10px 0;
}
