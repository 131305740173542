@charset "UTF-8";


* {
	box-sizing: border-box;
	font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
	font-weight: 400;
}
address, em {
	font-style: normal;
}


/* ---------- reset  ---------- */
html, body {
    width: 100%;
}

html {
	/*overflow-y: scroll; */ /* Firefox scroll-on */
}

body {
	background-color: rgb(233,237,243);
	color: rgb(51,51,51);
	font-size: 1em;
	line-height: 1.3em;
	letter-spacing: 0.01em;
}

dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,input,p,blockquote,fieldset,div {
	margin: 0;
	padding: 0;
}

table,pre,code,select,option,input,textarea,kbd,var,ins,del,samp {
	font-size: 14px;
}

optgroup {
	font-size: 14px;
	font-weight:bold;
}

h1,h2,h3,h4,h5,h6 {
	font-weight: normal;
	font-size: 14px;
}
p {
	font-size: 14px;
	line-height: 2;
	margin: 0 0 1em 0;
	text-align: left;
}
ul li,ol li {
	list-style: none;
}

table,th,td {
	margin: 0;
	padding: 0;
	border-collapse: separate;
	border-spacing: 0;
	text-align: center;
}

th,caption {
	text-align: left;
}

address,cite,dfn,em,b,strong,var,th,ins,del,samp {
	font-weight: normal;
	font-style: normal;
}

img,fieldset {
	border: none;
}
hr {
	border: solid rgb(205,205,205);
	border-width:1px 0 0 0;
	height:1px;
	clear: right;
}
.align_center {
	text-align: center;
}

select {
	color: rgb(51,51,51);
}

/* Link Style */

a,a:link,a:active,a:visited {
	color: rgb(51,51,51);
	text-decoration: none;
	outline: none;
}
a:focus {
	outline: none;
}
a:hover {
	color: rgb(51,51,51);
}

a img {
	border: 0;
}
a:hover img{
	filter: alpha(opacity=60);
	-moz-opacity:0.6;
	opacity:0.6;
}

.top_nomargin {
	margin-top: 0!important;
}
.bottom_nomargin {
	margin-bottom: 0!important;
}
.text_strong {
	font-weight: bold;
}

.text_cap {
	font-size: 14px;
	line-height: 1.3;
}
.text_long {
	letter-spacing: -0.1em;
}
.text_underline {
	text-decoration: underline;
}
.align_center {
	text-align: center!important;
}
.align_left {
	text-align: left!important;
}
.align_right {
	text-align: right!important;
}
.blockcontents {
	display: block;
}
.margintop10 {
	margin-top: 10px;
}
.margintop30 {
	margin-top: 30px;
}
.margintop40 {
	margin-top: 40px;
}
.marginbottom0 {
	margin-bottom: 0!important;
}
.marginbottom60 {
	margin-bottom: 60px!important;
}
.marginleft0 {
	margin-left: 0!important;
}
.margintopmainus30 {
	margin-top: -30px;
}
.cap {
	font-size: 14px;
	margin-top: 8px!important;
	line-height: 1.2!important;
}
.strong_copy {
	font-size: 20px;
	text-align: center;
}
.big_copy {
	font-size: 28px;
	margin-bottom: 0;
}
*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
 }
 
/* ---------  clearfix --------- */

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}
 
.clearfix {
	min-height: 1px;
}
 
* html .clearfix {
	height: 1px;
	/*\*//*/
	height: auto;
	overflow: hidden;
	/**/
}

.clear {
	clear: both;
}


/* ===============
.main_contents
================*/ 
.main_contents {
	width: 100%;
	margin: 0;
	display: flex;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	overflow-x: hidden;
	overflow-y: hidden;
}
.main_contents img {
	width: 100%;
	height: auto;
}
/* ===============
#left_side
================*/ 
#left_side {
	position: fixed;
	display: block;
	flex-basis: 220px;
	min-width: 220px;
	height: 100vh;
	background-color: rgb(255,255,255);
	margin-right: 20px;
	padding: 20px 16px;
	z-index: 1;
}
#left_side h1 {
	width: 130px;
	text-align: center;
	margin: 15px 0 20px 0;
	font-weight: bold;
	font-size: 25px;
}
#left_side div {
	width: 100%;
	font-size: 14px;
	font-weight: bold;
	margin: 0 auto;
}
#left_side div.on {
	background-color: rgb(234,238,243);
}
#left_side div.menu a {
	display: block;
	width: 100%;
	font-weight: bold;
	background-size: 21px;
	padding: 12px 6px 12px 40px;
}
#left_side div.menu a:hover {
	background-color: rgb(234,238,243);
}

#left_side .acd-check{
    display: none;
}
#left_side .acd-label{
	background-size: 22px;
	font-size: 14px;
	font-weight: bold;
    display: block;
	margin: 0 auto;
	padding: 12px 6px 12px 40px;
	cursor: pointer;
}
#left_side .acd-label:hover {
	background-color: rgb(234,238,243);
}
#left_side .acd-content{
    height: 0;
    opacity: 0;
    padding: 0 10px;
    transition: .5s;
    visibility: hidden;
}
#left_side .acd-check:checked + .acd-label + .acd-content{
    height: 40px;
    opacity: 1;
    padding: 0 0 0 10px;
    visibility: visible;
}
#left_side .acd-content p {
	font-size: 13px;
	margin: 0 0 0 20px;
	line-height: 1.4;
	/* background: url("../img/arrow_right_b.png") no-repeat left center; */
	background-size: 6px;
}
#left_side .acd-content p a {
	width: 100%;
	display: block;
	padding: 4px 10px 4px 15px;
}
#left_side .acd-content p a:hover {
	/* background: url("../img/arrow_right_b.png") no-repeat left center; */
	background-size: 6px;
	background-color: rgb(234,238,243);	
}
#left_side div.nav_setting_sub {
	display: block;
}
#left_side .nav_setting_sub p {
	font-size: 13px;
	margin: 0 0 0 30px;
	line-height: 1.7;
	/* background: url("../img/arrow_right_b.png") no-repeat left 5px center; */
	background-size: 6px;
}
#left_side .nav_setting_sub p a {
	width: 100%;
	display: block;
	padding: 2px 10px 2px 20px;
}
#left_side .nav_setting_sub p.on,
#left_side .nav_setting_sub p a:hover {
	/* background: url("../img/arrow_right_b.png") no-repeat left 5px center; */
	background-size: 6px;
	background-color: rgb(234,238,243);	
}

#left_side #logout_btn {
	width: 100%;
	height: 50px;
	position: absolute;
	bottom: 30px;
	left: 16px;
	display: flex;
	justify-content: flex-start;
}
#left_side #logout_btn .btn {
    margin: 0 10px;
	border: none;
	cursor: pointer;
	text-align: center;
	background-color: rgb(255,255,255);
	font-size: 13px;
	display: inline-block;
	padding: 8px 12px;
	border-radius: 4px;
	color: rgb(17,85,161);
	text-decoration: none!important;
}
#left_side #account_info #account_img {
	flex-basis: 50px;
	width: 50px;
	height: 50px;
	margin-right: 10px;
	overflow: hidden;
}
#left_side #account_info #account_img img {
	border-radius: 40px;
	width: 100%;
	height: auto;
}
#left_side #account_info #account_txt {
	flex-basis: 100px;
}
#left_side #account_info #account_txt #account_name {
	width: 100%;
	max-width: 100px;
	font-size: 14px;
	letter-spacing: -0.01em;
	margin: 0 0 2px 0;
	padding: 0;
	font-weight: bold;
	line-height: 1.3;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#left_side #account_info #account_txt #account_part {
	display: inline-block;
	background-color: rgb(17,85,161);
	color: rgb(255,255,255);
	padding: 6px 8px;
	border-radius: 4px;
	line-height: 1;
	margin: 0;
	font-size: 13px;
	max-width: 100px;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#left_side #account_info #account_nav {
	background-color: rgb(255,255,255);
	flex-basis: 40px;
	display: block;
	width: 100%;
	height: 40px;
	margin-right: 16px;
	border: none;
	cursor:pointer;
}

#left_side #account_info #account_nav img {
	width: 20px;
}

#logout_popup {
	position: fixed;
	width: 140px;
	height: 50px;
	display: block;
	background-color: rgb(255,255,255);
	text-align: center;
	left: 134px;
	bottom: 90px;
	border-radius: 6px;
	-webkit-filter:drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
  	box-sizing: border-box;
	z-index: 1;
}
#logout_popup:before {
	content: "";
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -15px;
	border: 12px solid transparent;
	border-top: 12px solid rgb(255,255,255);
	z-index: 2;
}

#logout_popup:after {
	content: "";
	position: absolute;
	bottom: -28px;
	left: 50%;
	margin-left: -17px;
	border: 14px solid transparent;
	border-top: 14px solid #dadada;
	z-index: 1;
}
#logout_popup .btn_logout {
	background-color: rgba(100, 100, 100, 0);
	text-align: center;
 	margin-top: 13px;
 	font-size: 14px;
	cursor: pointer;
	border: none;
}
#logout_popup .btn_logout {
	font-weight: bold;
}
#logout_popup:hover {
	background-color: rgb(234,238,243);	
	cursor: pointer;
}
#logout_popup:hover:before {
	border-top: 12px solid rgb(234,238,243);	
}

/* ===============
#right_contents
================*/ 
#right_contents {
	margin-left: 220px;
	flex-basis: calc(100%-220px);
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
#right_contents article {
	width: 100%;
	background-color: rgb(255,255,255);
	border-radius: 8px;
	padding: 20px;
}
#right_contents article h2 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 18px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
/* ===============
ログイン画面
================*/ 
#login_contents {
	width: 460px;
	margin: 120px auto 60px auto;
	border-radius: 8px;
	background-color: rgb(255,255,255);
	padding: 20px 30px;
}
#login_contents .login_alart {
	height: 28px;
	font-size: 14px;
	margin: 20px auto 20px auto;
	text-align: center;
	color: rgb(255,0,0);
}
#login_contents h1 {
	width: 208px;
	margin: 20px auto 20px auto;
	text-align: center;
    font-weight: bold;
    font-size: 25px;
}
#login_contents .login_area dl dt {
	float: left;
	width: 110px;
	clear: both;
	margin-bottom: 10px;
	line-height: 38px;
	font-size: 14px;
}
#login_contents .login_area dl dd {
	margin-bottom: 10px;
	padding-left: 120px;
}
#login_contents .login_area input.login_text {
	border: 1px solid rgb(17,85,161);
	width: 280px;
	padding: 6px 10px;
	border-radius: 4px;
}
#login_contents .login_area .login_btn {
	margin: 40px auto;
	width: 160px;
	display: block;
	border-radius: 4px;
	border: 1px solid rgb(17,85,161);
	text-align: center;
	font-size: 13px;
	padding: 6px 0;
	color: rgb(255,255,255);
	box-shadow: 0 1px 1px rgb(17,85,161);
	background-color: rgb(17,85,161);
}
#login_contents .login_area .login_btn:hover {
	background-color: rgb(15,76,144);
	color: rgb(255,255,255);
	cursor: pointer;
}
