@charset "UTF-8";

/* ===============
通知送信画面
================*/
#right_contents.notification_sending{
	margin-left: 221px;
	flex-basis: calc(100% - 221px);
	width: 100%;
	display: block;
}
.notification_sending div {
	width: 100%;
	padding: 50px 0 50px 50px;
	background-color:  rgb(255, 255, 255);
}
.notification_sending div dl {
	display:  flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	max-width: 1200px;
}
.notification_sending div dl dt {
	flex-basis: 10%;
	margin-bottom:  10px;
	font-size:  14px;
	font-weight: bold;
	max-width: 120px;
	min-width: 120px;
}
.notification_sending div dl dd {
	flex-basis: 90%;
	margin-bottom:  10px;
}
.notification_sending div dl.box {
	border: 1px solid rgb(17, 85, 161);
}
.notification_sending div dl.box.zokusei {
	width: 1000px;
	margin-top: 10px;
	padding: 20px 40px 20px 40px;
}
.notification_sending div dl.box.zokusei:before {
	content: "属性情報";
	height: 18px;
	margin: -30px 0 0 -35px;
	padding: 0 5px 0 5px;
	background-color: rgb(255, 255, 255);
	position: absolute;
	font-weight: bold;
}
.notification_sending div dl.box.location {
	width: 770px;
	margin: 10px 0 20px 0;
	padding: 10px 10px 5px 30px;
	font-size: 14px;
}
.notification_sending div dl.box.location:before {
	height: 18px;
	margin: -20px 0 0 -25px;
	padding: 0 5px 0 5px;
	display: inline-block;
	position: absolute;
	font-size: 14px;
	font-weight: bold;
	background-color: rgb(255, 255, 255);
	white-space: nowrap;
}
.notification_sending div dl.box.location.current_location_selected:before {
	content: "選択済み現在地";
}
.notification_sending div dl.box.location.living_location_selected:before {
	content: "選択済み居住地";
}
.notification_sending div dl.location dd.location {
    flex-basis: 100%;
	margin-bottom: 0;
}
.notification_sending div dl.location dd.location select {
    width: 150px;
	height: 30px;
   	margin: 0 10px 5px 0;
   	font-size: 13px;
	background-color: rgb(255,255,255);
	border: 1px solid rgb(17,85,161);
   	border-radius: 4px;
}
.notification_sending div dl.location input[type=button].location {
	height: 21px;
	margin: -20px 0 5px -20px;
	padding: 0 5px 0 5px;
	border: none;
	color: #333;
	font-size: 14px;
	font-weight: bold;
	background-color: rgb(255,255,255);
	cursor: pointer;
}
.notification_sending div dl.location input[type=button].location.down {
	padding-right: 20px;
	background: url("../img/arrow_down.png") no-repeat 95% center rgb(255, 255, 255);
	background-size: 8px;
}
.notification_sending div dl.location input[type=button].location.up {
	padding-right: 20px;
	background: url("../img/arrow_up.png") no-repeat 95% center rgb(255, 255, 255);
	background-size: 8px;
}
.notification_sending div dl.box dt {
	flex-basis: 20%;
	margin-bottom:  20px;
	font-size:  14px;
	font-weight: bold;
}
.notification_sending div dl.box dd {
	flex-basis: 80%;
	margin-bottom:  10px;
}
.notification_sending div dl dd label {
	font-size: 14px;
}
.notification_sending div dl dd input[type=text] {
	width: 600px;
	border: 2px;
	border: 1px solid rgb(17, 85, 161);
}
.notification_sending div dl dd input[type=text].border_red {
	border: 1px solid rgb(255, 128, 128);
}
.notification_sending div dl dd input[type=text].key {
	width: 200px;
	margin: 0 20px 5px 22.8px;
}
.notification_sending div dl dd input[type=text].value {
	width: 200px;
	margin: 0 0 0 10px;
}
.notification_sending div dl dd input[type=radio] {
	display: none;
}
.notification_sending div dl dd label.additional {
	white-space: nowrap;
}
.notification_sending div dl dd label.off {
	color: #999;
}
.notification_sending div dl dd label.radio {
	position: relative;
	padding-left: 27px;
	margin-right: 20px;
}
.notification_sending div dl dd label.radio.location {
	float: left;
}
.notification_sending div dl dd label.radio:hover {
	cursor: pointer;
}
.notification_sending div dl dd label.radio:before,
.notification_sending div dl dd label.radio:after {
	content: "";
	position: absolute;
	display: block;
	top: 50%; 
}
.notification_sending div dl dd label.radio:before {
	width: 14px; 
	height: 14px;
	top: 4px;
	left: 4px;
	border-radius: 50%;
	background: rgb(17,85,161); 
	opacity: 0;
}
.notification_sending div dl dd label.radio:after {
	width: 22px; 
	height: 22px;
	top: 0;
	left: 0;
	border: 2px solid #ccc;
	border-radius: 50%;
}
.notification_sending div dl dd input[type=radio]:checked + label.radio:before {
	opacity: 1;
}
.notification_sending div dl dd label.radio:hover:after {
	border-color: rgb(17,85,161);
}
.notification_sending div dl dd input[type=checkbox] {
	display: none;
}
.notification_sending div dl dd label.checkbox {
	position: relative;
	padding-left: 20px;
	margin-right: 20px;
	white-space: nowrap;
}
.notification_sending div dl dd label.checkbox.location {
	display: block;
	float: left;
	min-width: 119.13px;
	margin: 2px 10px 2px 0;
}
.notification_sending div dl dd label.checkbox:before,
.notification_sending div dl dd label.checkbox:after {
	content: "";
	position: absolute;
	display: block;
}
.notification_sending div dl dd label.checkbox:before {	
	width: 16px;
	height: 16px;
	top: 2px;
	left: 0px;
	border: 1px solid #999;
	border-radius: 2px;
}
.notification_sending div dl dd input[type=checkbox]:checked + label.checkbox:after {
	width: 9px;
	height: 14px;
	top: 2px;
	left: 4px;
	transform: rotate(40deg);
	border-bottom: 3px solid rgb(17,85,161);
	border-right: 3px solid rgb(17,85,161);
}
.notification_sending div dl dd input[type=checkbox]:checked + label.checkbox.off:after {
	border-bottom: 3px solid #999;
	border-right: 3px solid #999;
}
.notification_sending div dl dd textarea {
	width: 600px;
	height: 70px;
	border: 1px solid rgb(17, 85, 161);
}
.notification_sending div dl dd textarea.border_red {
	border: 1px solid rgb(255, 128, 128);
}
.notification_sending div button.btn {
	width: 100px;
	margin: 20px 0 0 400px;
	display: block;
	border-radius: 4px;
	border: 1px solid rgb(17,85,161);
	text-align: center;
	font-size: 13px;
	padding: 8px 0;
	box-shadow: 0 1px 1px rgb(17,85,161);
	background-color: rgb(255,255,255);
	color: rgb(255,255,255);
	background-color: rgb(17,85,161);
	cursor: pointer;
}
.notification_sending div button.btn:hover {
	background-color: rgb(4,71,145);
}